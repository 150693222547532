export const types = {
  login: "[auth] login",
  logout: "[auth] logout",
  setClientInfo: "[concat] ClientInfo",
  setAuthInfo: "[concat] AuthInfo",
  shop: "Shop",
  shopConcat: "[concat] Shop",
  amazon: "amazon",
  meli: "mercadolibre",
  shopify: "shopify"
};
