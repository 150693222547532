import colors from "assets/theme/base/colors";

// React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const { transparent } = colors;

const select = {
  styleOverrides: {
    select: {
      alignItems: "center",
      padding: `0 ${pxToRem(10)} !important`,

      "& .Mui-selected": {
        backgroundColor: transparent.main
      }
    },

    selectMenu: {
      background: "none",
      height: "none",
      minHeight: "none",
      overflow: "unset"
    }
  }
};

export default select;
