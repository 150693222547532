import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";

const { white } = colors;
const { borderWidth } = borders;

const stepConnector = {
  styleOverrides: {
    root: {
      color: "rgba(189, 189, 189, 1)",
      transition: "all 200ms linear",

      "&.Mui-active": {
        color: white.main
      },

      "&.Mui-completed": {
        color: white.main
      }
    },

    alternativeLabel: {
      top: "30%",
      left: "-10%",
      right: "90%"
    },

    line: {
      borderWidth: `${borderWidth[2]} !important`,
      borderColor: "currentColor",
      opacity: 0.5
    }
  }
};

export default stepConnector;
