// React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const dialogActions = {
  styleOverrides: {
    root: {
      padding: pxToRem(16)
    }
  }
};

export default dialogActions;
