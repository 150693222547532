import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";

// React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const { inputBorderColor, info, grey, transparent } = colors;
const { size } = typography;

const inputOutlined = {
  styleOverrides: {
    root: {
      backgroundColor: transparent.main,
      fontSize: size.sm,
      borderRadius: 0,

      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: inputBorderColor
      },

      "&.Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: info.main
        }
      }
    },

    notchedOutline: {
      borderColor: inputBorderColor
    },

    input: {
      color: grey[700],
      padding: pxToRem(12),
      backgroundColor: transparent.main
    },

    inputSizeSmall: {
      fontSize: size.xs,
      padding: pxToRem(10)
    },

    multiline: {
      color: grey[700],
      padding: 0
    }
  }
};

export default inputOutlined;
