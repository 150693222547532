import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";

// React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const { borderRadius } = borders;
const { light } = colors;

const linearProgress = {
  styleOverrides: {
    root: {
      height: pxToRem(12),
      borderRadius: borderRadius.md
    },

    colorPrimary: {
      backgroundColor: light.main
    },

    colorSecondary: {
      backgroundColor: light.main
    },

    bar: {
      height: pxToRem(12),
      borderRadius: borderRadius.sm,
      transition: "width 0.6s ease !important"
    }
  }
};

export default linearProgress;
