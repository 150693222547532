import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import App from "./App";
import { MaterialUIControllerProvider } from "./context";

const isProd = process.env.NODE_ENV !== "development";

if (isProd) {
  Sentry.init({
    dsn: "https://b15ba54a18d0859d506ecd6c0ff3ebd5@o4506631973896192.ingest.sentry.io/4506631976386560",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          "https://plataformacreizer.com",
          /^\https:\/\/plataformacreizer.com.*/
        ]
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false
      })
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.5, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    enabled: isProd
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <MaterialUIControllerProvider>
    <App />
  </MaterialUIControllerProvider>
);
