import colors from "assets/theme/base/colors";

// React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const { info } = colors;

const stepIcon = {
  styleOverrides: {
    root: {
      background: info.main,
      fill: info.main,
      strokeWidth: pxToRem(10),
      width: pxToRem(28),
      height: pxToRem(28),
      borderRadius: "50%",
      transition: "all 200ms linear"

      // "&.Mui-active": {
      //   background: white.main,
      //   fill: white.main,
      //   stroke: white.main,
      //   borderColor: white.main,
      //   boxShadow: boxShadow([0, 0], [0, 2], white.main, 1)
      // },

      // "&.Mui-completed": {
      //   background: white.main,
      //   fill: white.main,
      //   stroke: white.main,
      //   borderColor: white.main,
      //   boxShadow: boxShadow([0, 0], [0, 2], white.main, 1)
      // }
    }
  }
};

export default stepIcon;
