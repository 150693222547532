import colors from "assets/theme/base/colors";

// React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const { white } = colors;

const sidenav = {
  styleOverrides: {
    root: {
      width: pxToRem(250),
      whiteSpace: "nowrap",
      border: "none"
    },

    paper: {
      width: pxToRem(250),
      backgroundColor: white.main,
      border: "none"
    },

    paperAnchorDockedLeft: {
      borderRight: "none"
    }
  }
};

export default sidenav;
