import typography from "assets/theme/base/typography";
import colors from "assets/theme/base/colors";

// React helper functions
import pxToRem from "assets/theme/functions/pxToRem";
import rgba from "assets/theme/functions/rgba";

const { fontWeightRegular } = typography;
const { dark } = colors;

const stepLabel = {
  styleOverrides: {
    label: {
      fontSize: pxToRem(16),
      color: `${rgba(dark.main, 0.8)} !important`,
      fontWeight: `${fontWeightRegular} !important`,

      "&.Mui-active": {
        fontWeight: `${fontWeightRegular} !important`,
        color: `${rgba(dark.main, 0.8)} !important`
      },

      "&.Mui-completed": {
        fontWeight: `${fontWeightRegular} !important`,
        color: `${rgba(dark.main, 0.8)} !important`
      }
    }
  }
};

export default stepLabel;
